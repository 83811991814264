/***
 *
 *   DATA SPECS
 *   View Data Specs
 *
 **********/

import React, {Fragment, useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {
  AppLayoutContext, AuthContext, Button,
  DataSpecNumberCell,
  DateCell,
  Loader,
  ReactTable,
  useAPI
} from 'components/lib';
import Style from "../../components/hero/hero.tailwind";
import {CreateDataSpecModal} from "./shared/CreateDataSpecModal";

export function DataSpecs(props){

  const dataSpecs = useAPI('/api/dataSpec');
  const customers = useAPI('/api/customer/');
  const users = useAPI('/api/user/');
  const transponderModels = useAPI('/api/transponderModels/');
  const context = useContext(AuthContext);
  const isAdmin = useAPI('/api/adminPanel/admin/' + context.user?.user_id);

  let navigate = useNavigate();
  const [openDataSpecModal, setOpenDataSpecModal] = useState(false);
  const [duplicateOrCreateToggle, setDuplicateOrCreateToggle] = useState(false);
  const [isGlobalDataspec, setIsGlobalDataspec] = useState(false);
  // Add buttons to nav header (needed even if empty, to clear stale buttons from prev pages)
  const [navButtons, setNavButtons] = useContext(AppLayoutContext);
  useEffect(() => {
    setNavButtons([]);
  }, []);



  const columns = React.useMemo(() => [
    {
      Header: "DS Number",
      //accessor: 'number',
      accessor: (row) => {
        return row.number.split('_')[0]
      },
      Cell: DataSpecNumberCell,
    },
    {
      Header: "Organization",
      id: "customer_id",
      accessor: (row) => {
        return row && row.customer_id && customers.data ? customers.data.find(u => u.id === row.customer_id).name: row.customer_id
      },
    },
    {
      Header: "Description",
      accessor: (row) => {
        return row.description && row.description.length > 130 ? row.description.slice(0,130) + '...': row.description;
      },
    },
    {
      Header: "Printing",
      accessor: (row) => {
        if (!row.printConfig) {
          return '-'
        }
        if (row.printConfig.printRecords.length <= 0) {
          return 'No records'
        }
        return `${row.printConfig.printRecords.length} ${row.printConfig.printRecords.length === 1 ? 'Record' : 'Records'}`;
      },
    },
    {
      Header: "Encoding",
      accessor: (row) => {
        if (!row.transponderConfigs || row.transponderConfigs.length <= 0) {
          return '-'
        }
        if (row.transponderConfigs.flatMap(tc => tc.dataRecords).length <= 0) {
          return `${row.transponderConfigs.length} ${row.transponderConfigs.length === 1 ? 'Chip' : 'Chips'}, No records`
        }
        return `${row.transponderConfigs.length} ${row.transponderConfigs.length === 1 ? 'Chip' : 'Chips'}, ${row.transponderConfigs.flatMap(tc => tc.dataRecords).length} ${row.transponderConfigs.flatMap(tc => tc.dataRecords).length === 1 ? 'Record' : 'Records'}`;
      },
    },
    {
      Header: "Chip(s)",
      accessor: (row) => {
        if (!row.transponderConfigs || row.transponderConfigs.length <= 0) {
          return '-'
        }
        let chips = '';
        row.transponderConfigs.forEach((tc,idx) => {
          chips += `${idx > 0 ? ', ' : ''}${transponderModels.data.find(u => u.id === tc.transponder_model_id).name}`
        });

        return chips;
      },
    },
    {
      Header: "Assigned To",
      accessor: (row) => {
        return row && row.assigned_to_id && users.data ? users.data.find(u => u.id === row.assigned_to_id).name: row.assigned_to_id
      },
    },
    {
      Header: "Created",
      accessor: 'created_at',
      Cell: DateCell,
    },
    {
      Header: "Created By",
      accessor: (row) => {
        return row && row.created_by_id && users.data ? users.data.find(u => u.id === row.created_by_id).name: row.created_by_id
      },
    },
  ], [users, customers])

  return (
      <Fragment>

        { dataSpecs.data == null || dataSpecs.loading || users.loading || customers.loading || transponderModels.loading ?
            <Loader /> :
            <>
              {/*<Button goto='/dataspec/new' text='New Data Spec' small className={ Style.button + " m-2 mb-3 bg-orange-500 hover:bg-orange-700"}/>*/}
              <div className={"flex"}>
                <Button action={() => {
                  setOpenDataSpecModal(true);
                  setIsGlobalDataspec(false);
                  setDuplicateOrCreateToggle(false);
                }} text='New Data Spec' small className={ Style.button + " m-2 mb-3 bg-orange-500 hover:bg-orange-700"}/>
                <Button action={() => {
                  setDuplicateOrCreateToggle(true);
                  setIsGlobalDataspec(false);
                  setOpenDataSpecModal(true);
                }} text='Duplicate Existing Dataspec' small className={ Style.button + " m-2 mb-3 bg-orange-500 hover:bg-orange-700"}/>

                {isAdmin.data.isAdmin ?
                    <Button action={() => {
                      setOpenDataSpecModal(true);
                      setIsGlobalDataspec(true);
                      setDuplicateOrCreateToggle(false);
                    }} text='Create Global Dataspec' small className={ Style.button + " m-2 mb-3 bg-orange-500 hover:bg-orange-700"}/>
                    : ''}
              </div>
              <ReactTable columns={columns} data={dataSpecs.data} />
              <CreateDataSpecModal open={openDataSpecModal}
                                   setOpen={setOpenDataSpecModal}
                                   afterCreate={(data) => {
                                     navigate(`/dataSpec/${data.id}`);
                                   }}
                                   is_duplicate={duplicateOrCreateToggle}
                                   isGlobalDataspec={isGlobalDataspec}
              />
            </>
        }
      </Fragment>
  )
}
